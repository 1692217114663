import _kindOf from "kind-of";
import _isAccessorDescriptor from "is-accessor-descriptor";
import _isDataDescriptor from "is-data-descriptor";
var exports = {};
var typeOf = _kindOf;
var isAccessor = _isAccessorDescriptor;
var isData = _isDataDescriptor;

exports = function isDescriptor(obj, key) {
  if (typeOf(obj) !== "object") {
    return false;
  }

  if ("get" in obj) {
    return isAccessor(obj, key);
  }

  return isData(obj, key);
};

export default exports;